<template>
    <div>
        <div class="cont-search">
            <div class="search-l">
                <input placeholder="keywords" v-model="searchText" type="text">
            </div>
            <img src="@/assets/phone2/sousuo.png" alt="" @click="searchGame()">
            <!-- <div class="search-r">
            </div> -->
        </div>
        <div class="top-class">
            <div class="top-class-card"  v-for="item in topList.games" :key="item.id" @click="goselect(item.key_name)">
                <img :src="item.logo" alt="">
                <p>{{item.key_name}}</p>
            </div>
        </div>
        <adContent></adContent>
        <div class="cont-st cont-list">
            <div class="cont-st-h">
                <p>
                    <img :src="firstList.url" alt="">
                    {{firstList.title}} GAMES
                </p>
                <div @click="goselect(firstList.title)">more</div>
            </div>
            <div class="cont-list-card" v-for="items in firstList.games" :key="items.id" @click="goDetails(items)">
                <img :src="items.logo" alt="">
                <p class="no-warp">{{items.title}}</p>
            </div>
            <span v-for="item in spanNum" :key="item+'s'"></span>
        </div>

        <div class="cont-st grid-list">
            <div class="cont-st-h">
                <p>
                    <img :src="newList.url" alt="">
                    {{newList.title}} GAMES
                </p>
                <div @click="goselect(newList.title)">more</div>
            </div>
            <div class="mobile-Carousel">
                <div class="mobile-grid-body">
                    <div class="grid-card"
                    v-for="item in newList.games"
                    :key="item.id"
                    @click="goDetails(item)">
                        <img :src="item.logo" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="cont-st overflow-list">
            <div class="cont-st-h">
                <p>
                    <img :src="rankList.url" alt="">
                    {{rankList.title}} GAMES
                </p>
                <div @click="goselect(rankList.title)">more</div>
            </div>
            <div class="mobile-Carousel">
                <div class="mobile-Carousel-body">
                    <div class="Carousel-card"
                    v-for="item in rankList.games"
                    :key="item.id"
                    @click="goDetails(item)">
                        <img :src="item.logo" alt="">
                        <p class="no-warp">{{item.title}}</p>
                        <van-rate
                        v-model="item.rating"
                        readonly
                        :size="10"
                        color="#000000"
                        void-icon="star"
                        void-color="#eee"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="cont-st cont-list">
            <div class="cont-st-h">
                <p>
                    <img :src="gameList.url" alt="">
                    {{gameList.title}} GAMES
                </p>
                <div @click="goselect(gameList.title)">more</div>
            </div>
            <div class="cont-list-card" v-for="items in gameList.games" :key="items.id" @click="goDetails(items)">
                <img :src="items.logo" alt="">
                <p class="no-warp">{{items.title}}</p>
            </div>
            <span v-for="item in spanNum" :key="item+'s'"></span>
        </div>
    </div>
</template>

<script>
import { isMobile, scrollToTop } from "@/utils/api"
import eventBus from '@/utils/eventBus'
import { getGame, getGameByKey } from "@/api/games"
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent

        },
        data() {
            return {
                // topClass,
                gameList:[],
                allList:[],
                gameListC:[],
                firstList:{},
                newList:{},
                rankList:{},
                topList:{},
                spanNum:0,
                searchText:''
            }
        },
        mounted() {
            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }
            let menukey = this.$route.params.menuKey;
            let is
            if(menukey){
                this.getMenuGame(menukey)
                is = 0
            }else{
                is = 1
            }
            this.getList(is);
            eventBus.$on('selectMenu',key=>{                    
                this.getMenuGame(key)
            });
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            async getList(is){
                
                let topList = await getGame({count:8})
                this.topList = {
                    title: 'topClass',
                    games:topList
                }

                let firstList = await getGame({count:9})
                this.firstList = {
                    title: 'Hot',
                    games:firstList,
                    url: require('@/assets/class/hot.png')
                }

                let newList = await getGame({count:20})
                this.newList = {
                    title: 'New',
                    games:newList,
                    url: require('@/assets/class/ic_new1.png')
                }

                let rankList = await getGame({count:10})
                this.rankList = {
                    title: 'Popular',
                    games:rankList,
                    url: require('@/assets/class/ic_popular1.png')
                }

                if(is){
                    let gameList = await getGame()

                    this.gameList = {
                        title: 'All',
                        games:gameList,
                        url: require('@/assets/class/ic_all1.png')

                    }
                }
            },
            async getMenuGame(name){
                let games = await getGameByKey({category:name})
                this.gameList = {
                    title: name,
                    games,
                    url: require('@/assets/class/ic_all1.png')
                }
                
                eventBus.$emit('showmenu',0)
                eventBus.$emit('showfenlei',0)

                scrollToTop(2300)
            },
            goselect(item){
                this.$router.push({
                    path: '/selectGame',
                    query:{
                        id: 'more',
                        key: item
                    }
                })
            },
            searchGame(){
                this.showMenu = false;
                eventBus.$emit('showfenlei',false)
                if(this.$route.path != '/selectGame'){
                    this.$router.push({
                        path: '/selectGame',
                        query: {
                            id: 'search',
                            key: this.searchText
                        }
                    })
                }else{
                    eventBus.$emit('searchgame',this.searchText);
                }
            }
        },
    }
</script>

<style lang="less" scoped>
// .my-swipe{
//     margin-bottom: 28px;
//     .swipe-item{
//         padding: 12px 20px 0;
//         box-sizing: border-box;
//         display: flex;
//         justify-content: space-between;
//         img{
//             width: 165px;
//             height: 165px;
//             border-radius: 15px;
//         }
//     }
// }
// .cont-nine{
//     padding: 0 20px;
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;
//     .nine-card{
//         width: 101px;
//         border-radius: 15px;
//         margin-bottom: 15px;
//         img{
//             border: 2px solid #FFFFFF;
//             width: 101px;
//             height: 101px;
//             border-radius: 15px;
//         }
//         p{
//             white-space: nowrap;
//             font-family: PingFangSC, PingFang SC;
//             font-weight: 600;
//             font-size: 14px;
//             color: #1E1B4C;
//             line-height: 20px;
//             text-align: left;
//         }
//     }
// }
// .cont-class{
//     display: flex;
//     margin-bottom: 16px;
//     padding: 0 20px;
//     justify-content: space-between;
//     div{
//         width: 80px;
//         height: 80px;
//         background: linear-gradient( 180deg, #FFFFFF 0%, #FFECEB 100%);
//         box-shadow: 0px 2px 8px 0px rgba(214,83,77,0.72);
//         border-radius: 10px;
//         border: 1px solid #FFFFFF;
//         img{
//             width: 54px;
//             height: 54px;
//             display: block;
//             margin: 0 auto;
//         }
//         p{
//             text-align: center;
//             font-family: PingFangSC, PingFang SC;
//             font-weight: 500;
//             font-size: 14px;
//             color: #994B46;
//             line-height: 20px;
//             font-style: normal;
//         }
//     }
// }
.cont-search{
    display: flex;
    margin: 10px auto 11px;
    width: 338px;
    height: 40px;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    line-height: 40px;
    border-radius: 10px;


    background: #66CCFF;
    border-radius: 10px;
    border: 1px solid #00A3F4;
    .search-l{
        width: 281px;
        height: 40px; 
        border-radius: 10px;
        overflow: hidden;
        input{
            &::placeholder {
                color: #FFF;
            }
            background: #66CCFF;
            margin-left: 13px;
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #000;
            line-height: 22px;
            text-align: left;
            font-style: normal;
        }
    }
    img{
        margin-right: 12px;
        width: 22px;
        height: 22px;
    }
}
.top-class{
    padding: 20px 20px 0;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 21px;
    text-align: center;
    .top-class-card{
        width: 100%;
        img{
            width: 100%;
            // height: 70px;
            border-radius: 50%;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            font-style: normal;
        }
    }
}
.cont-st{
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .cont-st-h{
        width: 100%;
        height: 40px;

        background: #66CCFF;
        border-radius: 10px;
        border: 1px solid #00A3F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
            img{
                width: 22px;
                height: 22px;
            }
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFF;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            // margin-left: 12px;
        }
        div{
            margin-right: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFF;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
        }
    }
    .cont-st-card{
        margin: 12px 0;
        padding-top: 6px;
        box-sizing: border-box;
        width: 152px;
        border-radius: 11px;
        text-align: center;
        img{
            width: 152px;
            height: 152px;
            border-radius: 15px;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            text-align: left;
            font-style: normal;
        }
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #818181;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
        }
    }
}
.cont-ed{
    .cont-ed-card{
        width: 162px;
        height: 86px;
        padding: 5px;
        margin: 8px 0 0 0;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        .ed-card-l{
            width: 76px;
            height: 76px;
            border-radius: 10px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .ed-card-r{
            width: 70px;
            overflow: hidden;
            p{
                margin-bottom: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
            .play{
                margin: 0 auto;
                width: 63px;
                height: 21px;
                text-align: center;
                line-height: 21px;
                background: #504BA9;
                border-radius: 12px;

                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 9px;
                color: #FFFFFF;
                font-style: normal;
                text-transform: uppercase;
            }
        }
    }
}
.mid-list{
    .mid-list-rank{
        display: flex;
        justify-content: space-between;
        align-content: center;
        .rank-card{
            padding: 6px 8px 0;
            box-sizing: border-box;
            width: 106px;
            height: 152px;
            background: #F3EBDF;
            border-radius: 11px;
            text-align: center;
            img{
                width: 90px;
                height: 90px;
            }
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                font-style: normal;
            }
        }
    }
    .mid-list-card{
        width: 335px;
        height: 80px;
        margin: 7px 0;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
            width: 80px;
            height: 80px;
            background: #D8D8D8;
            border-radius: 13px;
            // border: 1px solid #FFFFFF;
        }
        .mid-c-mid{
            width: 162px;
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                text-align: left;
                font-style: normal;
            }
            div{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #747474;
                line-height: 17px;
                text-align: left;
                font-style: normal;
            }
        }
        .play{
            width: 74px;
            height: 25px;
            background: #D8C8B1;
            border-radius: 5px;
            line-height: 25px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 10px;
            color: #FFFFFF;
            text-align: center;
            font-style: normal;
        }
    }
}
.cont-list{
    // &::after{
    //     content: '   ';
    //     flex: auto;
    //     display: block;
    //     width: 100px;
    // }
    .cont-list-card{
        width: 100px;
        margin: 16px 0 0 0;
        img{
            width: 100px;
            height: 100px;
            border-radius: 15px;
            border: 2px solid #FFFFFF;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #000;
            line-height: 20px;
            text-align: left;
            font-style: normal;
        }
    }
    span{
        width: 100px;
    }
}
.overflow-list{
    .mobile-Carousel{
        margin-top: 20px;
        width: calc(100vw - 30px);
        // margin: 0 auto;
        overflow: hidden;
        .mobile-Carousel-body{
            overflow: auto; 
            white-space: nowrap; 
            word-break: break-all; 
            width: 100%;
            .Carousel-card{
                margin-right: 9px;
                display: inline-block;
                width: 106px;
                height: 152px;
                background: #66CCFF;
                border-radius: 11px;
                border: 1px solid #00A3F4;
                text-align: center;
                img{
                    display: block;
                    margin: 9px auto 6px;
                    width: 90px;
                    height: 90px;
                    background: #D8D8D8;
                    border-radius: 11px;
                }
                p{
                    width: 90px;
                    margin: 0 auto;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: #000000;
                    font-style: normal;
                }
            }
        }
    }
}
.grid-list{
    .mobile-Carousel{
        margin-top: 20px;
        width: calc(100vw - 30px);
        // margin: 0 auto;
        overflow: hidden;
        .mobile-grid-body{
            overflow: auto; 
            white-space: nowrap; 
            word-break: break-all; 
            width: 100%;
            display: grid;
            grid-template-columns: repeat(10,1fr);
            grid-gap: 14px;
            .grid-card{
                img{
                    width: 121px;
                    height: 121px;
                    background: #D8D8D8;
                    border-radius: 18px;
                    border: 1px solid #000000;
                }
            }
        }
    }
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.warp-2{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>